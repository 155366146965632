<template>
  <div class="period">
    <div class="period-wrap">
      <div class="period-content">
        <a :class="`period-button ${period === 'last-month' && 'selected'}`" @click="$emit('period', 'last-month')">
          <span>{{ $locale.words["last"] + " " + $locale.words["month"] }}</span>
        </a>
        <a :class="`period-button ${period === 'last-3-months' && 'selected'}`" @click="$emit('period', 'last-3-months')">
          <span>{{ $locale.words["last"] + "s 3 " + $locale.words["months"] }}</span>
        </a>
        <a :class="`period-button ${period === 'last-6-months' && 'selected'}`" @click="$emit('period', 'last-6-months')">
          <span>{{ $locale.words["last"] + "s 6 " + $locale.words["months"] }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["period"],
};
</script>

<style lang="scss">
.period {
  padding: $mpadding;
  &-wrap {
    overflow-x: auto;
  }
  &-content {
    display: flex;
    justify-content: space-evenly;
    gap: $mpadding/2;
  }
  &-button {
    @include Flex(inherit, center, center);
    background-color: $gray2;
    font-size: 80%;
    cursor: pointer;
    flex: 1;
    text-align: center;
    padding: $mpadding/1.7;
    border-radius: 2pc;
    &.selected {
      background-color: $primary_color_saturate;
    }
  }
}
</style>
